
import {computed, defineComponent, ref, watch, onMounted} from "vue";
import {IonButton, IonContent, IonInput, IonItem, IonLabel, IonPage, IonText, onIonViewDidEnter} from "@ionic/vue";
import {LoginUser} from "@/domain/command/user/user.command";
import {useUserApi} from "@/domain/service/api/use-user-api";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "Login",
  components: {
    IonButton,
    IonContent,
    IonPage,
    IonInput,
    IonItem,
    IonLabel,
    IonText
  },

  setup() {
    const {login} = useUserApi();
    const store = useStore();
    const route = useRouter();
    const isLogging = ref(false);
    const error = ref(false);
    const vuexIsLogged = computed(() => store.getters.isLogged);
    const loginUser = ref<LoginUser>({
      username: '',
      password: '',
    });
    const submit = async () => {
      isLogging.value = true;
      error.value = false;
      try {
        await login(loginUser.value);
      } catch (e) {
        error.value = true;
      } finally {
        isLogging.value = false;
      }
    };
    watch(vuexIsLogged, () => {
      if (vuexIsLogged.value)
        route.push({name: 'MaterialsList'});
    });
    onIonViewDidEnter(() => {
      if (store.getters.isLogged)
        route.push({name: 'MaterialsList'});
    });

    return {
      submit,
      isLogging,
      loginUser,
      error,
      vuexIsLogged
    };
  }

})
